import {
  useCallback, useContext, useEffect, useMemo, useState, useRef,
} from 'react';

import { services } from '../../api';
import { PemsDomain } from '../../consts';
import { useAppCommon } from '../AppCommon';
import { useConnection } from '../Connection';
import { useCrewAuth } from '../CrewAuth';
import { useDomain } from '../Domain';
import UpdatesContext, { defaultStateValue, UpdatesResponse } from './UpdatesContext';
import { transformApiResponse } from './updatesUtils';

const POLLING_TIMEOUT = 15000; // in ms

const updatesServices = {
  [PemsDomain.IADP]: services.checkIadpUpdates,
  [PemsDomain.OT]: services.checkOtUpdates,
};

const UpdatesProvider = ({ children }: { children: React.ReactNode }) => {
  const { crmId, authenticated } = useCrewAuth();
  const { appIsActive } = useAppCommon();
  const { online } = useConnection();
  const { pemsDomain } = useDomain();

  const intervalIdRef = useRef<NodeJS.Timeout | undefined>(undefined);
  const [lastUpdates, setLastUpdates] = useState(defaultStateValue);

  const clearUpdates = useCallback(() => {
    if (intervalIdRef.current !== undefined) {
      clearInterval(intervalIdRef.current);
      intervalIdRef.current = undefined;
    }
  }, []);

  // Fetch updates endpoint
  const fetchUpdates = useCallback(async () => {
    if (!crmId || pemsDomain === PemsDomain.NLWS) {
      return Promise.resolve();
    }
    return updatesServices[pemsDomain]({ crmId })
      .then(({ data }: { data: UpdatesResponse }) => {
        const { result } = data;
        if (result) {
          setLastUpdates(transformApiResponse(result));
        }
      })
      .catch((error) => {
        if (error?.response?.status === 401 && intervalIdRef.current !== undefined) {
          clearUpdates();
        }
      });
  }, [clearUpdates, crmId, pemsDomain]);

  // Poll the updates endpoint
  useEffect(() => {
    if (online && appIsActive && authenticated) {
      fetchUpdates();
      const intervalId = setInterval(fetchUpdates, POLLING_TIMEOUT);
      intervalIdRef.current = intervalId;
    } else {
      clearUpdates();
    }
    return clearUpdates;
  }, [fetchUpdates, authenticated, online, clearUpdates, appIsActive]);

  const ctx = useMemo(() => ({ ...lastUpdates, fetchUpdates }), [fetchUpdates, lastUpdates]);

  return <UpdatesContext.Provider value={ctx}>{children}</UpdatesContext.Provider>;
};

export const useUpdates = () => useContext(UpdatesContext);

export default UpdatesProvider;
