import {
  useCallback, useContext, useEffect, useMemo, useState,
} from 'react';

import { useHistory } from 'react-router-dom';

import AppCommonContext from './AppCommonContext';

const AppCommonProvider = ({ children }: { children: React.ReactNode }) => {
  const history = useHistory();
  const [footerVisible, setFooterVisible] = useState(true);
  const [dirty, setDirty] = useState(false);
  const [appIsActive, setAppIsActive] = useState(true);

  const showFooter = useCallback(() => setFooterVisible(true), []);
  const hideFooter = useCallback(() => setFooterVisible(false), []);
  const toggleFooter = useCallback(() => setFooterVisible((prevState) => !prevState), []);

  const clearDirtyAndRedirect = useCallback((route: string) => {
    setDirty(false);
    setTimeout(() => {
      history.push(route);
    }, 0);
  }, [history]);

  useEffect(() => {
    const onResume = () => setAppIsActive(true);
    const onPause = () => setAppIsActive(false);
    const onFocus = () => setAppIsActive(true);
    const onBlur = () => setAppIsActive(false);
    if (window.cordova) {
      document.addEventListener('resume', onResume, false);
      document.addEventListener('pause', onPause, false);
    } else {
      window.addEventListener('blur', onBlur, false);
      window.addEventListener('focus', onFocus, false);
    }
    return () => {
      if (window.cordova) {
        document.removeEventListener('resume', onResume, false);
        document.removeEventListener('pause', onPause, false);
      } else {
        window.removeEventListener('blur', onBlur, false);
        window.removeEventListener('focus', onFocus, false);
      }
    };
  }, []);

  const ctx = useMemo(() => ({
    appIsActive,
    dirty,
    footerVisible,
    hideFooter,
    setDirty,
    showFooter,
    toggleFooter,
    clearDirtyAndRedirect,
  }), [appIsActive, dirty, footerVisible, hideFooter, setDirty, showFooter, toggleFooter, clearDirtyAndRedirect]);

  return <AppCommonContext.Provider value={ctx}>{children}</AppCommonContext.Provider>;
};

export const useAppCommon = () => useContext(AppCommonContext);

export default AppCommonProvider;
