// eslint-disable-next-line max-classes-per-file
import { useCallback } from 'react';
import { DateTimePicker as MuiDateTimePicker, DateTimePickerProps } from '@material-ui/pickers';

import parseISO from 'date-fns/parseISO';
import formatISO from 'date-fns/formatISO';

import { useProfile } from '@crew-webui/common/hooks';

const MuiDateTimePickerNonTypeSafe: any = MuiDateTimePicker;

type Props = Omit<DateTimePickerProps, 'onChange'> & {
  classes?: Record<string, any>;
  initialFocusedDate?: string;
  maxDate?: string;
  minDate?: string;
  onChange: (value: string) => void;
  value?: string | null;
};

const DatePicker = ({
  initialFocusedDate, maxDate, minDate, onChange, value, ...otherProps
}: Props) => {
  const { profile: { dateFormats } } = useProfile();

  const handleChange = useCallback((dateTimeObj: number | Date) => {
    const dateTimeStr = formatISO(dateTimeObj, { representation: 'complete' });
    onChange(dateTimeStr);
  }, [onChange]);

  return (
    <MuiDateTimePickerNonTypeSafe
      autoOk
      disableToolbar={false}
      format={dateFormats.DATE_TIME}
      initialFocusedDate={initialFocusedDate && parseISO(initialFocusedDate)}
      maxDate={maxDate && parseISO(maxDate)}
      minDate={minDate && parseISO(minDate)}
      onChange={handleChange}
      value={value}
      variant="dialog"
      {...otherProps}
    />
  );
};

export default DatePicker;
