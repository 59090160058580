import { DateTime } from 'luxon';

export enum EventContext {
  Roster = 'roster',
  Flight = 'flight',
  OtherOffers = 'otherOffers',
}

export type EventId = string | number;

export interface RosterInterval {
  from: DateTime;
  to: DateTime;
}

export interface RosterPeriod extends RosterInterval {
  crmPublishedEndDateTime: DateTime;
}

export interface BlockMonth extends RosterInterval {
  id: string;
  name: string;
}

export interface OpenTimePairingsPerDay {
  date: string;
  count: number;
}

export interface EventDescription {
  aircraftSubType?: string;
  aircraftTypeCode?: string;
  aircraftTypeCodes?: string[];
  flightNumber?: string;
  flightSuffix?: string;
  groundActivityCode?: string;
  groundTransportCode?: string;
  homebase?: string;
  hotelCode?: string;
  hotelName?: string;
  pairingClassifications?: string;
  pairingNumber?: string;
  rank?: string;
  registration?: string;
  rosterTagCode?: string;
  acReg?: string;
  acDepTerminal?: string;
  acDepGate?: string;
}

export interface Briefing {
  dateLocal?: string;
  localTime?: string;
  timeUtc?: string;
}

export enum ActivityProgress {
  OT = 'OT',
  OT_ACT = 'OT_ACT',
  OT_ERR = 'OT_ERR',
  TT = 'TT',
  TT_ACT = 'TT_ACT',
  TT_ERR = 'TT_ERR',
}

export enum TimeMode {
  UTC = 'UTC',
  LocalAtAirport = 'LocalAtAirport',
}

export interface EventFromServer {
  id: number;
  logicalId: string;
  events?: EventFromServer[];

  fromDateTimeLtAtAirport: string;
  toDateTimeLtAtAirport: string;
  fromDateTimeLtAtHb: string;
  toDateTimeLtAtHb: string;
  fromDateTimeUTC: string;
  toDateTimeUTC: string;
  fromDateTimeSchedUTC?: string;
  toDateTimeSchedUTC?: string;
  simBriefingDateTimeUTC?: string;
  simBriefingDateTimeLtAtHb?: string;
  simBriefingDateTimeLt?: string;
  simDebriefingDateTimeUTC?: string;
  simDebriefingDateTimeLtAtHb?: string;
  simDebriefingDateTimeLt?: string;

  activityProgress?: ActivityProgress;
  arrival?: { airportCode?: string };
  checkinCheckoutControlled?: boolean;
  crewMemberPartOfFlight?: boolean;
  customAttributes?: string[];
  departure?: { airportCode?: string };
  details?: string[];
  eventDescription?: EventDescription;
  groundActivityCode?: string;
  originalEventType?: string;
  remark?: string;
  roles?: { id: number; name: string }[];
  rosterChangeType?: string[];
  selectable?: 'YES' | 'NO' | 'NEVER';
}

export interface Event {
  activityProgress?: ActivityProgress;
  attributes?: string[];
  checkinCheckoutControlled?: boolean;
  crewMemberPartOfFlight?: boolean;
  croppedAtEnd?: boolean;
  croppedAtStart?: boolean;
  details?: string[];
  eventDescription?: EventDescription;
  eventHeader?: (string | ((t: T) => string))[];
  events?: Event[];
  flightNumber?: string;
  fromAirport?: string;
  fromDate?: string;
  fromDateAtCalendar: DateTime;
  fromDateAtCalendarCropped?: DateTime;
  fromDateLocal?: string;
  fromDateTime?: DateTime;
  fromDateTimeLocal?: DateTime;
  fromDateTimeSched?: DateTime;
  fromDateTimeUTC?: string;
  fromLocalTime?: string;
  fromTimeUtc?: string;
  hotelCode?: string;
  hotelName?: string;
  id: number;
  logicalId: string;
  onlyArrival?: boolean;
  onlyDeparture?: boolean;
  originalEventType: string;
  remark?: string;
  renderIdx?: number;
  roles?: { id: number; name: string }[];
  selectable?: 'YES' | 'NO' | 'NEVER';
  simBriefing?: Briefing;
  simDebriefing?: Briefing;
  suspended?: boolean;
  toAirport?: string;
  toDate?: string;
  toDateAtCalendar: DateTime;
  toDateAtCalendarCropped?: DateTime;
  toDateLocal?: string;
  toDateTime?: DateTime;
  toDateTimeLocal?: DateTime;
  toDateTimeSched?: DateTime;
  toDateTimeUTC?: string;
  toLocalTime?: string;
  toTimeUtc?: string;
}

export type RoleType = {
  id: number;
  shortName?: string;
  name?: string;
  updateByCrewMember?: boolean;
  setOnEvent?: boolean;
};

export interface FlightDesignator {
  carrier: string;
  number: number;
  suffix: string;
}

export interface FlightInfo {
  airport: string;
  terminal: string;
  gate: string;
  depDtSchedAtApLt: string;
  depDtSched: string;
  actualDepDtAtApLt: string;
  actualDepDt: string;
}

export interface AircraftRotationInfo {
  dsgn: FlightDesignator;
  depAp: string;
  arrAp: string;
  actualTimeAtApLt: string;
  actualTimeAtAp: string;
}

export type AdditionalFlightDetails = {
  id: number;
  logicalId: string;
  arrival: FlightInfo;
  departure: FlightInfo;
  scheduledDiff: number;
  currentDiff: number;
  overScheduled: number;
  flightNumber: string;
  dsgn: FlightDesignator;
  acReg: string;
  acSubType: string;
  prevAc: AircraftRotationInfo;
  nextAc: AircraftRotationInfo;
};
