import { createContext } from 'react';

type AppCommonContextType = {
  appIsActive: boolean;
  dirty: boolean;
  footerVisible: boolean;

  hideFooter: () => void;
  setDirty: (dirty: boolean) => void;
  showFooter: () => void;
  toggleFooter: () => void;
  clearDirtyAndRedirect: (route: string) => void;
};

const AppCommonContext = createContext<AppCommonContextType>({
  appIsActive: true,
  dirty: false,
  footerVisible: true,

  hideFooter: () => {},
  setDirty: () => {},
  showFooter: () => {},
  toggleFooter: () => {},
  clearDirtyAndRedirect: () => {},
});

export default AppCommonContext;
